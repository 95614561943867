<template>
  <div :id="id" :class="['m-product-introduce-single', 'card-spacing', isPC ? 'z-web' : 'z-phone', theme.primaryColor ? theme.primaryColor : '']">
    <div class="u-product-list">
      <VisualTitle :title="title" @visual="handleVisual" />
      <div
        v-for="(item, index) in list.value"
        :key="`product-${index}`"
        :class="['m-product', list.value.length > 1 ? 'z-some' : '']"
      >
        <div class="u-wrap">
          <div v-if="isPC" class="u-img">
            <VisualItem :visualKeys="[item.img.visualKeys, item.img_2x.visualKeys]" @visual="handleVisual">
              <CMSImage
                :img="item.img.value"
                :img2x="item.img_2x.value"
                class="u-img1"
              />
              <CMSImage
                :img="item.img.value"
                :img2x="item.img_2x.value"
                class="u-img2"
              />
            </VisualItem>
          </div>
          <div v-else class="u-img">
            <VisualItem :visualKeys="[item.img_2x_h5.visualKeys, item.img_3x_h5.visualKeys]" @visual="handleVisual" class="u-img-items">
              <CMSImage
                :img="item.img_2x_h5.value ? item.img_2x_h5.value : item.img.value"
                :img2x="item.img_3x_h5.value ? item.img_3x_h5.value : item.img_2x.value"
                class="u-img1"
              />
              <!-- <CMSImage
                :img="item.img_2x_h5.value ? item.img_2x_h5.value : item.img.value"
                :img2x="item.img_3x_h5.value ? item.img_3x_h5.value : item.img_2x.value"
                class="u-img2"
              /> -->
            </VisualItem>
          </div>
          <div class="info">
            <p class="tlt">
              <VisualItem :visualKeys="item.title.visualKeys" @visual="handleVisual">
                {{ item.title.value }}
              </VisualItem>
            </p>
            <p class="desc">
              <VisualItem :visualKeys="item.desc.visualKeys" @visual="handleVisual">
                {{ item.desc.value }}
              </VisualItem>
            </p>
            <div v-if="isPC" class="more-btn">
              <VisualItem v-if="item.linkText && item.linkText.value" :visualKeys="[item.linkText.visualKeys, item.link.visualKeys]" @visual="handleVisual">
                <CMSLink
                  :link="item.link.value"
                  class-name="btn"
                >
                  {{ item.linkText.value }}
                </CMSLink>
              </VisualItem>
            </div>
          </div>
        </div>
        <div v-if="!isPC" class="more-btn">
          <VisualItem v-if="item.linkText && item.linkText.value" :visualKeys="[item.linkText.visualKeys, item.link.visualKeys]" @visual="handleVisual">
            <CMSLink
              :link="item.link.value"
              class-name="btn"
            >
              {{ item.linkText.value }}
            </CMSLink>
          </VisualItem>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import config from './config.json'
import Load from '~/assets/js/load.js'
import { fixConfig } from '~/assets/js/visualFix'
import CMSImage from '~/components/common/Image'
import CMSLink from '~/components/common/Link'
import VisualItem from '~/components/common/VisualItem'
import VisualTitle from '~/components/common/VisualTitle'

const componentKey = 'ProductIntroduceSingle'

export default {
  components: {
    CMSImage,
    CMSLink,
    VisualItem,
    VisualTitle
  },
  props: {
    id: {
      type: [String, Number],
      default: componentKey
    },
    mode: {
      type: String,
      default: ''
    },
    reqData: {
      type: Object,
      default() {
        return {}
      }
    },
    isPC: {
      type: Boolean,
      default: true
    }
  },
  data() {
    const data = fixConfig(_.cloneDeep(config[componentKey]), this.mode === 'dev' ? {
      id: componentKey,
      reqData: Load(config, componentKey)
    } : this.$store.getters.getComponent(this.id))

    this.$store.commit('updateComponentById', {
      id: this.id || componentKey,
      data
    })

    return {
      ...data
    }
  },
  computed: {
    theme() {
      return this.$store.state.theme
    }
  },
  methods: {
    handleVisual(obj) {
      this.$emit('visual', obj)
    }
  }
}
</script>

<style lang="postcss">
@import '~/assets/css/4mixin.css';

.m-product-introduce-single {
  &.z-phone {
    background: #f7f8fa;
    /* margin: pxtorem(90) pxtorem(40); */
    padding: 0 pxtorem(40);

    .u-product-list {
      .m-product {
        width: 100%;

        .u-img {
          position: relative;

          &-items {
            position: relative;
          }

          .u-img1 {
            width: 100%;
            /* height: pxtorem(406); */
            position: relative;
            z-index: 4;
          }

          .u-img2 {
            width: pxtorem(552);
            height: pxtorem(406);
            z-index: 0;
            position: absolute;
            top: pxtorem(114);
            left: pxtorem(118);
            opacity: 0.1;
            z-index: 1;
          }
        }

        .info {
          width: 100%;
          padding: pxtorem(40) pxtorem(40) pxtorem(44);
          /* margin-top: pxtorem(40); */
          position: relative;
          z-index: 1;
          background-color: #fff;
          box-shadow: 0 pxtorem(8) pxtorem(40) rgba(47, 56, 111, 0.156305);

          .tlt {
            font-size: pxtorem(36);
            margin-bottom: pxtorem(30);
            line-height: pxtorem(50);
            color: #333;
            font-weight: bold;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }

          .desc {
            line-height: pxtorem(52);
            font-size: pxtorem(26);
            color: #666;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 6;
            -webkit-box-orient: vertical;
          }
        }
      }

      .more-btn {
        .btn {
          width: pxtorem(264);
          height: pxtorem(80);
          display: block;
          margin: pxtorem(70) auto 0;
          line-height: pxtorem(80);
          font-size: pxtorem(28);
          background-color: $baseBlue;
          text-align: center;
          color: #fff;

          &:hover {
            background-color: $activeBlue;
          }

          &:active {
            background: $activeBlue;
          }
        }
      }
    }

    &.red {
      .u-product-list .more-btn .btn {
        background-color: $baseRed;

        &:hover {
          background-color: $activeRed;
        }

        &:active {
          background-color: $activeRed;
        }
      }
    }
  }

  &.z-web {
    margin: 60px 0;

    .u-product-list {
      width: 100%;
      margin: 0 auto;
      text-align: left;

      .m-visual-item {
        display: block;
        margin: 0 auto;
      }

      .m-product {
        width: 100%;
        margin-top: 50px;

        .u-wrap {
          position: relative;
          display: block;
          width: 1190px;
          min-height: 478px;
          margin: 57px auto 0;
          box-sizing: border-box;
        }

        .u-img {
          position: relative;
          display: inline-block;
          width: 508px;
          height: 374px;
          vertical-align: top;
          float: left;

          .m-visual-item,
          img {
            width: 508px;
            height: 374px;
            position: relative;
            z-index: 1;
          }

          .u-img1 {
            width: 508px;
            height: 374px;
            z-index: 0;
            position: absolute;
            top: 64px;
            left: 55px;
            opacity: 0.1;
          }
        }

        .info {
          float: right;
          display: inline-block;
          width: 484px;
          height: 343px;
          vertical-align: top;
          text-align: left;
          box-sizing: border-box;
          position: relative;

          .tlt {
            display: inline-block;
            margin-top: 81px;
            margin-bottom: 52px;
            font-size: 28px;
            position: relative;
            z-index: 2;
            width: 462px;
            color: #333;

            div {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }

          .desc {
            min-height: 72px;
            font-size: 14px;
            line-height: 24px;
            color: #666;
            margin-bottom: 52px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 6;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .more-btn {
            position: relative;
            border: none;

            .btn {
              display: inline-block;
              width: 132px;
              height: 40px;
              line-height: 40px;
              font-size: 14px;
              background-color: $baseBlue;
              color: #fff;
              text-align: center;

              &:hover {
                background-color: #325cf3;
              }

              &:active {
                background-color: #2a54e8;
              }
            }
          }
        }

        &.z-some:nth-child(odd) {
          background-color: #f7f8fa;

          .u-img {
            float: right;

            img {
              &::after {
                right: -51px;
              }
            }
          }

          .info {
            float: left;
          }
        }
      }
    }

    &.red {
      .m-product .info .more-btn .btn {
        background-color: $baseRed;

        &:hover {
          background-color: $activeRed;
        }
      }
    }
  }
}
</style>

<template>
  <VisualItem v-if="title && title.value" :visualKeys="title.visualKeys" @visual="handleVisual" class-name="common-title">
    <ul v-if="theme.titleStyle == 'iconTitle'" class="iconTitle">
      <li class="common-title-before" />
      <li class="common-title-before2" />
    </ul>
    <pre class="h2">{{ title.value }}</pre>
  </VisualItem>
</template>

<script>
import VisualItem from '~/components/common/VisualItem'
export default {
  components: {
    VisualItem
  },
  props: {
    title: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {}
  },
  computed: {
    theme() {
      return this.$store.state.theme
    }
  },
  methods: {
    handleVisual(obj) {
      this.$emit('visual', obj)
    }
  }
}
</script>

<style lang="postcss">
@import '~/assets/css/4mixin.css';

.z-phone {
  .common-title {
    font-size: pxtorem(48);
    margin-bottom: pxtorem(80);
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;

    .iconTitle {
      display: inline-block;
      position: relative;
      height: pxtorem(68);
      width: pxtorem(68);
      margin-right: pxtorem(6);

      .common-title-before {
        display: inline-block;
        width: pxtorem(36);
        height: pxtorem(26);
        background: linear-gradient(180deg, #1c67ff -108.71%, #245eff 100%);
        transform: matrix(-1, 0, -0.5, 1, 0, 0);
        position: absolute;
        top: pxtorem(24);
        left: pxtorem(10);
      }

      .common-title-before2 {
        display: inline-block;
        width: pxtorem(36);
        height: pxtorem(26);
        background: linear-gradient(180deg, #7ee2ef 0%, rgba(255, 255, 255, 0) 100%);
        opacity: 0.5;
        transform: matrix(-1, 0, -0.5, 1, 0, 0);
        position: absolute;
        top: pxtorem(16);
        left: pxtorem(26);
      }
    }

    .h2 {
      max-width: pxtorem(550);
      display: inline-block;
      font-size: pxtorem(48);
      color: #142033;
      font-weight: bold;
      line-height: pxtorem(68);
      text-align: center;
      white-space: pre-wrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-bottom: 0;
      font-family: auto;
    }
  }

  &.red {
    .common-title .iconTitle .common-title-before {
      background: $baseRed;
    }

    .common-title .iconTitle .common-title-before2 {
      background: linear-gradient(180deg, #ef7e7e 0%, rgba(255, 255, 255, 0.24) 100%);
    }
  }
}

.z-web {
  .common-title {
    display: flex !important;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: relative;

    .iconTitle {
      display: inline-block;
      position: relative;
      width: 42px;
      height: 42px;

      .common-title-before {
        display: inline-block;
        width: 22px;
        height: 16px;
        background: linear-gradient(180deg, #1c67ff -108.71%, #245eff 100%);
        transform: matrix(-1, 0, -0.4, 1, 0, 0);
        position: absolute;
        top: 15px;
        left: -5px;
      }

      .common-title-before2 {
        display: inline-block;
        width: 22px;
        height: 16px;
        background: linear-gradient(180deg, #7ee2ef 0%, rgba(255, 255, 255, 0) 100%);
        opacity: 0.5;
        transform: matrix(-1, 0, -0.4, 1, 0, 0);
        position: absolute;
        top: 9px;
        left: 4px;
      }
    }

    .h2 {
      font-size: 32px;
      display: inline;
      line-height: 48px;
      color: #000;
      font-weight: 600;
      text-align: center;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 1000px;
      margin-bottom: 0;
      font-family: auto;
    }
  }

  &.red {
    .common-title {
      .iconTitle {
        .common-title-before {
          background: $baseRed;
        }

        .common-title-before2 {
          background: linear-gradient(180deg, #ef7e7e 0%, rgba(255, 255, 255, 0.24) 100%);
        }
      }
    }
  }
}
</style>
